<template>
  <div class="item-box">
    <img class="icon-box" :src="icon">
    <div class="content-box">
      <span>{{title}}</span>
      <div>{{subtitle}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CalcHomeItem",
    props: {
      icon: {
        type: String,
      },
      title: {
        type: String,
      },
      subtitle: {
        type: String,
      }
    }
  }
</script>

<style scoped>
.item-box {
  width: 154px;
  margin-top: 16px;
  padding: 10px 7px;
  background: #FFFFFF;
  border: 0px solid #CCCCCC;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.icon-box {
  width: 34px;
  height: 34px;
}

.content-box {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: #999999;
}

.content-box span {
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  color: #333;
}
</style>