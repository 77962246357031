<template>
  <div class="page">
    <img
      class="banner-box"
      src="@/assets/imgs/calc/banner.png"
      @click="onAnnuity"
    />
    <div class="content-box">
      <calc-home-item
        :icon="img1"
        title="医疗保险计算器"
        subtitle="月入金、趸交金额计算"
        @click.native="$router.push({ name: 'MedicalIndexPage' })"
      ></calc-home-item>
      <calc-home-item
        :icon="img2"
        title="生育保险计算器"
        subtitle="生育医疗费和津贴计算"
        @click.native="$router.push({ name: 'MaternityInsurancePage' })"
      ></calc-home-item>
      <calc-home-item
        :icon="img3"
        title="工伤保险计算器"
        subtitle="不同认证等级赔付计算"
        @click.native="$router.push({ name: 'WorkInjuryInsurancePage' })"
      ></calc-home-item>
      <calc-home-item
        :icon="img4"
        title="失业保险计算器"
        subtitle="失业保险金计算"
        @click.native="$router.push({ name: 'UnemploymentInsurancePage' })"
      ></calc-home-item>
      <calc-home-item
        :icon="img5"
        title="个税计算器"
        subtitle="个人所得税计算"
        @click.native="$showInfo('敬请期待')"
      ></calc-home-item>
      <calc-home-item
        :icon="img6"
        title="公积金计算器"
        subtitle="公积金缴费计算"
        @click.native="$showInfo('敬请期待')"
      ></calc-home-item>
    </div>
  </div>
</template>

<script>
import CalcHomeItem from "./CalcHomeItem";

export default {
  components: {
    CalcHomeItem,
  },
  data() {
    return {
      img1: require("@/assets/imgs/calc/icon1.png"),
      img2: require("@/assets/imgs/calc/icon2.png"),
      img3: require("@/assets/imgs/calc/icon3.png"),
      img4: require("@/assets/imgs/calc/icon4.png"),
      img5: require("@/assets/imgs/calc/icon5.png"),
      img6: require("@/assets/imgs/calc/icon6.png"),
    };
  },
  methods: {
    onAnnuity() {
      this.$router.push({
        name: "AnnuityCalcPage",
      });
    },
  },
};
</script>

<style scoped>
.page {
  padding: 16px 24px;
  background: #f9fcff;
  min-height: calc(100vh - 42px);
}

.banner-box {
  width: 326px;
  height: 110px;
}

.content-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>